{
  "c": "BEETA",
  "calendar": {
    "addAppointment": "Lisää uusi",
    "downloadChecklist": "Tarkistuslista",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Ei vertaansa vailla olevia kokemuksia#",
        "noExperiences": "Ei vertaansa vailla olevia kokemuksia"
      }
    },
    "warnings": {
      "noExperiences": "Ei vertaansa vailla olevia kokemuksia"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Pikatoiminnot"
        }
      },
      "navAccount": {
        "help": {
          "label": "Tuki"
        },
        "switch": {
          "label": "Vaihda"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "päivä | päivää",
    "hours": "tunti | tuntia",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Siirry yleisnäkymään",
        "description": "Tililläsi ei ole tämän sivun katseluun tarvittavia oikeuksia",
        "title": "Sinulla ei ole oikeutta katsella tätä sivua"
      },
      "serverError": {
        "cta": "Yritä uudelleen",
        "description": "Sisäisen palvelinvirheen vuoksi emme voineet täyttää pyyntöäsi. Meille on ilmoitettu siitä. Yritä uudelleen jonkin ajan kuluttua.",
        "title": "Oho, tapahtui yllättävä virhe"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Myyjällä ei ole lompakoita aktivoituna"
      }
    },
    "validationErrors": {
      "invalidIban": "Virheellinen IBAN",
      "notAValidEmail": "Kentässä ei ole kelvollinen sähköpostiosoite",
      "notAValidMoneyAmount": "Virheellinen rahasumma",
      "notEmpty": "Kenttää ei saa jättää tyhjäksi",
      "notIdenticalIban": "Vahvistettu IBAN on oltava sama kuin IBAN",
      "unrecognisedDateTime": "Tunnistamaton päivämäärä ja aika"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Valitse, mihin käyttökokemukseen haluat määrittää uuden saatavuuden.",
      "title": "Lisää tapaaminen kokemukseen"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Päivitä",
      "title": "Bookingkitistä on saatavilla uusi versio"
    }
  },
  "pagination": {
    "next": "Seuraava",
    "previous": "Edellinen"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Tapaamisen lisääminen",
    "disableHere": "Poista pikakuvakkeet käytöstä täällä",
    "enableShortcuts": "Ota pikakuvakkeet käyttöön",
    "keyboardShortcuts": "Pikanäppäimet",
    "ok": "Ok",
    "powerShortcuts": "Pikanäppäimet",
    "search": "Hae",
    "searchShortcutToastInfoMac": "Paina uudelleen ⌘ + F aktivoidaksesi selaimen alkuperäisen haun",
    "searchShortcutToastInfoWindows": "Paina uudelleen Ctrl + F aktivoidaksesi selaimen alkuperäisen haun",
    "toastMessage": "Pikakuvakkeet ovat oletusarvoisesti käytössä.",
    "vendorShortcutToastInfoMac": "Paina uudelleen ⌘ + M aktivoidaksesi selaimen alkuperäisen pikakuvakkeen",
    "vendorShortcutToastInfoWindows": "Paina uudelleen Ctrl + M aktivoidaksesi selaimen alkuperäisen pikakuvakkeen",
    "vendorSwitch": "Myyjän vaihto"
  },
  "search": {
    "accepted": "HYVÄKSYTTY",
    "archived": "ARKISTOITU",
    "canceled": "PERUUTETTU",
    "coupon": "Kuponki",
    "declined": "HYLÄTTY",
    "expired": "ERÄÄNTYNYT",
    "failure": "EPÄONNISTUI",
    "feature": "Ominaisuus",
    "flexibleTicket": "Joustava lippu",
    "invalid": "Virheellinen",
    "noResultFound": "Tuloksia ei löytynyt",
    "noResultFoundMessage": "Hakusi ei tuottanut yhtään tulosta. Kokeile toista tapaa.",
    "open": "AVAA",
    "order": "Tilaus",
    "pending": "ODOTTAA",
    "reserved": "VARATTU",
    "search": "Hae",
    "searchEntryMessage": "Voit hakea:",
    "searchError": "Jokin meni pieleen...",
    "searchErrorMessage": "Pyrimme korjaamaan ongelman. Yritä pian uudelleen.",
    "searchFeatureEntryMessage": "Ominaisuudet nimien mukaan.",
    "searchOrderEntryMessage": "tilaukset tunnusten, asiakkaiden tai muiden tilaukseen liittyvien tietojen perusteella;",
    "searchScopes": {
      "all": "Kaikki",
      "coupons": "Kupongit",
      "features": "Ominaisuudet",
      "flexibleTickets": "Joustavat liput",
      "orders": "Tilaukset",
      "vouchers": "Tositteet"
    },
    "searchVoucherEntryMesaage": "Tositteet koodin, nimen tai kuvauksen mukaan;",
    "searchVoucherEntryMessage": "tositteet koodin, nimen tai kuvauksen mukaan;",
    "ticketTypes": {
      "all": "Kaikki",
      "coupons": "Kupongit",
      "features": "Ominaisuudet",
      "flexibleTickets": "Joustavat liput",
      "orders": "Tilaukset",
      "vouchers": "Tositteet"
    },
    "valid": "Voimassa oleva",
    "voucher": "Tosite"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Yritystiedot"
      },
      "contract": {
        "label": "Sopimus"
      },
      "main": {
        "label": "tili"
      },
      "profile": {
        "label": "Profiili"
      },
      "signOut": {
        "label": "Kirjaudu ulos"
      },
      "userManagement": {
        "label": "Käyttäjien hallinta"
      },
      "vendorSwitcher": {
        "label": "myyjän vaihto"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-maksut"
      },
      "applicaitonLimits": {
        "label": "Käyttörajat"
      },
      "application": {
        "label": "Sovellus"
      },
      "applicationLimits": {
        "label": "Käyttörajat"
      },
      "changeRates": {
        "label": "Muuta hintoja"
      },
      "chargeVouchers": {
        "label": "Maksutositteet"
      },
      "claimAccount": {
        "label": "Ota tili haltuun"
      },
      "createAccount": {
        "label": "Luo tili"
      },
      "dashboard": {
        "label": "Kojelauta"
      },
      "disableEventDates": {
        "label": "Poista tapahtuman päivämäärät käytöstä"
      },
      "emailConfiguration": {
        "label": "Sähköpostin määritys"
      },
      "importCoupons": {
        "label": "Tuo kupongit"
      },
      "importOrders": {
        "label": "Tuo tilaukset"
      },
      "importVouchers": {
        "label": "Tuo tositteet"
      },
      "invoices": {
        "label": "Laskut"
      },
      "KYCUnverified": {
        "label": "Vahvistamaton KYC"
      },
      "main": {
        "label": "Ylläpitäjä"
      },
      "manageAPI": {
        "label": "API:n hallinta"
      },
      "manageBkUsers": {
        "label": "bk-käyttäjien hallinta"
      },
      "mangopayDisputes": {
        "label": "Mangopay-kiistat"
      },
      "marketingPartners": {
        "label": "Markkinointikumppanit"
      },
      "outstandingPayments": {
        "label": "Maksamatta olevat maksut"
      },
      "paymentRequests": {
        "label": "Maksupyynnöt"
      },
      "payoutChannels": {
        "label": "Maksukanavat"
      },
      "paypalPaymentRequests": {
        "label": "PayPal-maksupyynnöt"
      },
      "provider": {
        "label": "Palveluntarjoaja"
      },
      "rates": {
        "label": "Hinnat"
      },
      "resellerPaymentStatus": {
        "label": "Jälleenmyyjän maksun tila"
      },
      "setupFee": {
        "label": "Asennusmaksu"
      },
      "translationTool": {
        "label": "Käännöstyökalu"
      },
      "unknownPaymentrequests": {
        "label": "Tuntemattomat maksupyynnöt"
      }
    },
    "beta": "BEETA",
    "calendar": {
      "day": {
        "label": "Päivän näkymä"
      },
      "feed": {
        "label": "Kalenterin syöte"
      },
      "main": {
        "label": "Kalenteri"
      },
      "month": {
        "label": "Kuukauden näkymä"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Paketit"
      },
      "experiences": {
        "label": "Tapahtumat"
      },
      "flexibleOffers": {
        "label": "Joustavat tarjoukset"
      },
      "main": {
        "label": "Varasto"
      },
      "products": {
        "label": "Tuotteet"
      },
      "resources": {
        "label": "Resurssit"
      }
    },
    "marketplace": {
      "main": {
        "label": "App Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Joustavat liput"
      },
      "main": {
        "label": "Tilaukset"
      },
      "orders": {
        "label": "Tilaukset"
      },
      "participants": {
        "label": "Osallistujat"
      },
      "promotionalCoupons": {
        "label": "Myynninedistämiskupongit"
      },
      "requests": {
        "label": "Pyynnöt"
      },
      "vouchers": {
        "label": "Tositteet"
      }
    },
    "overview": {
      "main": {
        "label": "Yleiskatsaus"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Kokonaistilastot"
      },
      "exports": {
        "label": "Vienti"
      },
      "main": {
        "label": "Raportit"
      },
      "monthlyStatement": {
        "label": "Kuukausittainen tiliote"
      },
      "statements": {
        "label": "Tiliotteet"
      },
      "statistics": {
        "label": "Tilastot"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Jälleenmyyjät"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Omat kumppanit"
      },
      "orders": {
        "label": "Tilaukset"
      },
      "platforms": {
        "label": "Jälleenmyyjien integraatiot"
      },
      "promote": {
        "label": "Mainosta paikallisia tapahtumia"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Kassa-asetukset"
      },
      "emailCampaigns": {
        "label": "Sähköpostikampanjat"
      },
      "localExperiences": {
        "label": "Paikalliset tapahtumat"
      },
      "main": {
        "label": "Myynti"
      },
      "paymentOptions": {
        "label": "Maksuvaihtoehdot"
      },
      "paymentProviders": {
        "label": "Maksupalveluntarjoajat"
      },
      "widgets": {
        "label": "Pienoisohjelmat"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalenterin syöte"
      },
      "emailConfiguration": {
        "label": "Sähköpostin määritys"
      },
      "invoiceLayout": {
        "label": "Laskun asettelu"
      },
      "invoiceNNumber": {
        "label": "Laskun numero"
      },
      "invoiceNumber": {
        "label": "Laskun numero"
      },
      "main": {
        "label": "Asetukset"
      },
      "modules": {
        "label": "Moduulit"
      },
      "ticketConfiguration": {
        "label": "Lipun määritys"
      },
      "translations": {
        "label": "Käännökset"
      },
      "vat": {
        "label": "ALV"
      },
      "voucherConfiguration": {
        "label": "Tositteen määritys"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App Marketplace"
      },
      "appointment": {
        "label": "Tapaamisen lisääminen"
      },
      "booking": {
        "label": "Varaus"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Ota yhteyttä tukeen"
      },
      "copyToClipboard": {
        "failure": "Toimittajan linkin kopiointi epäonnistui",
        "success": "Toimittajalle kopioitu linkki:"
      },
      "date": {
        "label": "Päivämäärä"
      },
      "experience": {
        "label": "Tapahtuma"
      },
      "knowledgeBase": {
        "label": "Tietämyskanta",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Ohjattu asennustoiminto (vahvistettava)"
      },
      "powerShortcuts": {
        "label": "Pikanäppäimet"
      }
    },
    "updates": {
      "main": {
        "label": "Tuotepäivitykset"
      },
      "mainBadge": {
        "label": "Uusi"
      }
    },
    "wallet": {
      "main": {
        "label": "Lompakko"
      },
      "partnerPayments": {
        "label": "Kumppanien maksut"
      },
      "verification": {
        "label": "Vahvistus"
      },
      "walletManagement": {
        "label": "Lompakon hallinta"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Käytettävissä olevat toimittajat",
    "dismiss": "Hylkää",
    "navigate": "Navigoida",
    "noResult": "Tuloksia ei löytynyt",
    "recentUsed": "Viimeksi käytetty",
    "resultsLabel": "Löydetyt myyjät",
    "searchError": "Jokin meni pieleen...",
    "searchErrorMessage": "Pyrimme korjaamaan ongelman. Yritä pian uudelleen.",
    "searchForVendor": "Etsi myyjää",
    "select": "Valitse",
    "storeResultsLabel": "Käytettävissä olevat myyjät",
    "supplierAlreadyInUseError": "Käytät tällä hetkellä tätä myyjätiliä",
    "switchVendor": "Vaihda myyjää",
    "wishmorning": "Hyvää huomenta"
  }
}