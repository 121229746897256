{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Legg til avtale",
    "downloadChecklist": "Sjekkliste",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Ingen matchende opplevelser",
        "noExperiences": "Ingen matchende opplevelser"
      }
    },
    "warnings": {
      "noExperiences": "Ingen matchende opplevelser"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Raske handlinger"
        }
      },
      "navAccount": {
        "help": {
          "label": "Hjelp"
        },
        "switch": {
          "label": "Koble til"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "dag | dager",
    "hours": "time | timer",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Gå til oversikt",
        "description": "Kontoen din mangler de nødvendige rettighetene for å vise denne siden",
        "title": "Du har ikke tillatelse til å se denne siden"
      },
      "serverError": {
        "cta": "Prøv igjen",
        "description": "Grunnet en intern serverfeil kunne vi ikke behandle din forespørsel. Vi er informert om saken. Prøv igjen litt senere.",
        "title": "Ooops, det oppsto en merkelig feil"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Leverandøren har ingen lommebøker aktivert"
      }
    },
    "validationErrors": {
      "invalidIban": "Ugyldig IBAN",
      "notAValidEmail": "Feltet inneholder ingen gyldig e-post",
      "notAValidMoneyAmount": "Ugyldig pengebeløp",
      "notEmpty": "Dette feltet må fylles ut",
      "notIdenticalIban": "Bekreft at IBAN må være det samme som IBAN",
      "unrecognisedDateTime": "Ukjent dato og klokkeslett"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Velg hvilken opplevelse du vil tilordne ny tilgjengelighet til.",
      "title": "Legg til avtale til opplevelsen"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Oppdater",
      "title": "En ny versjon av bookingkit er tilgjengelig"
    }
  },
  "pagination": {
    "next": "Neste",
    "previous": "Forrige"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Legg til avtale",
    "disableHere": "Deaktiver snarveier her",
    "enableShortcuts": "Aktiver snarveier",
    "keyboardShortcuts": "Hurtigtaster på tastaturet",
    "ok": "OK",
    "powerShortcuts": "Snarveier",
    "search": "Søk",
    "searchShortcutToastInfoMac": "Trykk på ⌘ + F igjen for å aktivere det opprinnelige nettlesersøket",
    "searchShortcutToastInfoWindows": "Trykk på Ctrl + F igjen for å aktivere det opprinnelige nettlesersøket",
    "toastMessage": "Snarveier er aktivert som standard.",
    "vendorShortcutToastInfoMac": "Trykk på ⌘ + M igjen for å aktivere den opprinnelige nettlesersnarveien",
    "vendorShortcutToastInfoWindows": "Tryk på Ctrl + M igjen for å aktivere den opprinnelige nettlesersnarveien",
    "vendorSwitch": "Leverandørbytte"
  },
  "search": {
    "accepted": "AKSEPTERT",
    "archived": "ARKIVERT",
    "canceled": "KANSELLERT",
    "coupon": "Kupong",
    "declined": "AVSLÅTT",
    "expired": "UTLØPT",
    "failure": "FEIL",
    "feature": "Egenskap",
    "flexibleTicket": "Fleksibel billett",
    "invalid": "Ugyldig",
    "noResultFound": "Fant ingen resultater",
    "noResultFoundMessage": "Søket ditt ga ingen resultater. Prøv en annen måte.",
    "open": "ÅPNE",
    "order": "Bestilling",
    "pending": "AVVENTER",
    "reserved": "RESERVERT",
    "search": "Søk",
    "searchEntryMessage": "Du kan søke:",
    "searchError": "Noe gikk galt.",
    "searchErrorMessage": "Vi jobber med å fikse problemet. Prøv igjen senere.",
    "searchFeatureEntryMessage": "Funksjoner etter navn.",
    "searchOrderEntryMessage": "bestillinger etter ID, kunde eller andre bestillingsrelaterte data:",
    "searchScopes": {
      "all": "Alle",
      "coupons": "Kuponger",
      "features": "Egenskaper",
      "flexibleTickets": "Fleksible billetter",
      "orders": "Bestillinger",
      "vouchers": "Kuponger"
    },
    "searchVoucherEntryMesaage": "Kuponger etter kode, tittel eller beskrivelse:",
    "searchVoucherEntryMessage": "Kuponger etter kode, tittel eller beskrivelse:",
    "ticketTypes": {
      "all": "Alle",
      "coupons": "Kuponger",
      "features": "Egenskaper",
      "flexibleTickets": "Fleksible billetter",
      "orders": "Bestillinger",
      "vouchers": "Kuponger"
    },
    "valid": "Gyldig",
    "voucher": "Kupong"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Bedriftsdata"
      },
      "contract": {
        "label": "Kontrakt"
      },
      "main": {
        "label": "konto"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Logg ut"
      },
      "userManagement": {
        "label": "Brukeradministrasjon"
      },
      "vendorSwitcher": {
        "label": "leverandørbytte"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-betalinger"
      },
      "applicaitonLimits": {
        "label": "Søknadsgrenser"
      },
      "application": {
        "label": "App"
      },
      "applicationLimits": {
        "label": "Søknadsgrenser"
      },
      "changeRates": {
        "label": "Endre priser"
      },
      "chargeVouchers": {
        "label": "Belaste kuponger"
      },
      "claimAccount": {
        "label": "Tildel konto"
      },
      "createAccount": {
        "label": "Tildel konto"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Deaktiver arrangementsdatoer"
      },
      "emailConfiguration": {
        "label": "E-postkonfigurasjon"
      },
      "importCoupons": {
        "label": "Importer kuponger"
      },
      "importOrders": {
        "label": "Importer bestillinger"
      },
      "importVouchers": {
        "label": "Importer kuponger"
      },
      "invoices": {
        "label": "Fakturaer"
      },
      "KYCUnverified": {
        "label": "KYC ubekreftet"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "Administrere API"
      },
      "manageBkUsers": {
        "label": "Administrer bk-brukere"
      },
      "mangopayDisputes": {
        "label": "Mangopay tvister"
      },
      "marketingPartners": {
        "label": "Markedsføringspartnere"
      },
      "outstandingPayments": {
        "label": "Utestående betalinger"
      },
      "payoutChannels": {
        "label": "Betalingskanaler"
      },
      "paypalPaymentRequests": {
        "label": "PayPal betalingsforespørsler"
      },
      "provider": {
        "label": "Leverandør"
      },
      "rates": {
        "label": "Priser"
      },
      "resellerPaymentStatus": {
        "label": "Forhandlers betalingsstatus"
      },
      "setupFee": {
        "label": "Avgift for oppsett"
      },
      "translationTool": {
        "label": "Oversettelsesverktøy"
      },
      "unknownPaymentrequests": {
        "label": "Ukjente betalingshenvendelser"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Dagsvisning"
      },
      "feed": {
        "label": "Kalenderfeed"
      },
      "main": {
        "label": "Kalender"
      },
      "month": {
        "label": "Månedsvisning"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Bundter"
      },
      "experiences": {
        "label": "Opplevelser"
      },
      "flexibleOffers": {
        "label": "Fleksible tilbud"
      },
      "main": {
        "label": "Lagerbeholdning"
      },
      "products": {
        "label": "Produkter"
      },
      "resources": {
        "label": "Ressurser"
      }
    },
    "marketplace": {
      "main": {
        "label": "App markedsplass"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Fleksible billetter"
      },
      "main": {
        "label": "Bestillinger"
      },
      "orders": {
        "label": "Bestillinger"
      },
      "participants": {
        "label": "Deltakere"
      },
      "promotionalCoupons": {
        "label": "Verdikuponger"
      },
      "requests": {
        "label": "Forespørsler"
      },
      "vouchers": {
        "label": "Kuponger"
      }
    },
    "overview": {
      "main": {
        "label": "Oversikt"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Aggregert statistikk"
      },
      "exports": {
        "label": "Eksporterer"
      },
      "main": {
        "label": "Rapporter"
      },
      "monthlyStatement": {
        "label": "Månedlig oppgave"
      },
      "statements": {
        "label": "Oppgaver"
      },
      "statistics": {
        "label": "Statistikk"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Forhandlere"
      },
      "marketplace": {
        "label": "Markedsplass"
      },
      "myPartners": {
        "label": "Mine partnere"
      },
      "orders": {
        "label": "Bestillinger"
      },
      "platforms": {
        "label": "Forhandleres integrasjoner"
      },
      "promote": {
        "label": "Markedsfør lokale opplevelser"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Kassekonfigurasjon"
      },
      "emailCampaigns": {
        "label": "E-postkampanjer"
      },
      "localExperiences": {
        "label": "Lokale opplevelser"
      },
      "main": {
        "label": "Selge"
      },
      "paymentOptions": {
        "label": "Betalingsalternativer"
      },
      "paymentProviders": {
        "label": "Betalingsleverandører"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalenderfeed"
      },
      "emailConfiguration": {
        "label": "E-postkonfigurasjon"
      },
      "invoiceLayout": {
        "label": "Fakturaoppsett"
      },
      "invoiceNNumber": {
        "label": "Fakturanummer"
      },
      "invoiceNumber": {
        "label": "Fakturanummer"
      },
      "main": {
        "label": "Innstillinger"
      },
      "modules": {
        "label": "Moduler"
      },
      "ticketConfiguration": {
        "label": "Billettkonfigurasjon"
      },
      "translations": {
        "label": "Oversettelser"
      },
      "vat": {
        "label": "MVA"
      },
      "voucherConfiguration": {
        "label": "Kupongkonfigurasjon"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App markedsplass"
      },
      "appointment": {
        "label": "Legg til avtale"
      },
      "booking": {
        "label": "Bestilling"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Kontakt kundeservice"
      },
      "date": {
        "label": "Dato"
      },
      "experience": {
        "label": "Opplevelse"
      },
      "knowledgeBase": {
        "label": "Kunnskapsbase"
      },
      "onboardingWizard": {
        "label": "Onboarding wizard (TBC)"
      },
      "powerShortcuts": {
        "label": "Snarveier"
      }
    },
    "updates": {
      "main": {
        "label": "Produkt | Produkter"
      },
      "mainBadge": {
        "label": "Legg til ny"
      }
    },
    "wallet": {
      "main": {
        "label": "Lommebok"
      },
      "partnerPayments": {
        "label": "API-betalinger"
      },
      "verification": {
        "label": "Bekreftelse"
      },
      "walletManagement": {
        "label": "Administrasjon av wallet"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Tilgjengelige selgere",
    "dismiss": "Avvis",
    "navigate": "Naviger",
    "noResult": "Fant ingen resultater",
    "recentUsed": "Nylig brukt",
    "resultsLabel": "Fant leverandører",
    "searchError": "Noe gikk galt.",
    "searchErrorMessage": "Vi jobber med å fikse problemet. Prøv igjen senere.",
    "searchForVendor": "Søk etter leverandør",
    "select": "Velg",
    "storeResultsLabel": "Tilgjengelige leverandører",
    "supplierAlreadyInUseError": "Du bruker for tiden denne forhandlerkontoen",
    "switchVendor": "Bytt leverandør"
  }
}