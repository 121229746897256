export const ADMIN_MODULE = 'admin';
export const APP_MARKETPLACE_MODULE = 'appMarketplace';
export const CALENDAR_MODULE = 'calendar';
export const INVENTORY_MODULE = 'inventory';
export const ORDERS_MODULE = 'order';
export const OVERVIEW_MODULE = 'overview';
export const REPORTS_MODULE = 'reports';
export const RESELLERS_MODULE = 'reports';
export const SELL_MODULE = 'sell';
export const SETTINGS_MODULE = 'settings';
export const USER_MODULE = 'account';
export const WALLET_MODULE = 'wallet';
