import { LOCAL_STORAGE_PREFIX } from '@/bootstrap/environment';
import { addToStorage, getFromStorage } from '@/services/localStorage';

const PREFIX = LOCAL_STORAGE_PREFIX ?? 'bk-app';
const CALENDAR_SETTINGS_KEY = `${PREFIX}-CALENDAR-SETTINGS`;

type CalendarSetting = {
    IMAGE_SETTINGS : boolean,
};

const getCalendarSettingsFromStorage = () => {
    try {
        return JSON.parse(getFromStorage(CALENDAR_SETTINGS_KEY) as string);
    } catch (e) {
        return undefined;
    }
};

export const addCalendarImageSettingsToStorage = (value: boolean) => {
    const calendarObjFromStorage = getCalendarSettingsFromStorage();
    const calendarObj:CalendarSetting = calendarObjFromStorage ? { ...calendarObjFromStorage, IMAGE_SETTINGS: value } : { IMAGE_SETTINGS: value };
    addToStorage(CALENDAR_SETTINGS_KEY, calendarObj);
};

export const getCalendarImageSettingFromStorage = () => {
    const calendarObj = getCalendarSettingsFromStorage();
    return !(calendarObj === null || calendarObj === undefined) ? calendarObj.IMAGE_SETTINGS : true;
};

export default {
    addCalendarImageSettingsToStorage,
    getCalendarImageSettingFromStorage,
};
