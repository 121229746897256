{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Añadir cita",
    "downloadChecklist": "Lista de control",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "No hay experiencias coincidentes",
        "noExperiences": "No hay experiencias coincidentes"
      }
    },
    "warnings": {
      "noExperiences": "No hay experiencias coincidentes"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Acciones rápidas"
        }
      },
      "navAccount": {
        "help": {
          "label": "Ayuda"
        },
        "switch": {
          "label": "Cambiar a"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "día | días",
    "hours": "hora | horas",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Ir al resumen",
        "description": "Su cuenta no tiene los derechos necesarios para ver esta página",
        "title": "No tiene permiso para ver esta página"
      },
      "serverError": {
        "cta": "Inténtelo de nuevo",
        "description": "Debido a un error interno del servidor, no podemos completar su solicitud. Se nos ha notificado al respecto. Inténtelo de nuevo más tarde.",
        "title": "Lo sentimos, ha ocurrido un error inesperado"
      },
      "walletManagement": {
        "noWalletErrorMessage": "El proveedor no tiene ningún monedero activado"
      }
    },
    "validationErrors": {
      "invalidIban": "IBAN no válido",
      "notAValidEmail": "El campo no es un correo electrónico válido",
      "notAValidMoneyAmount": "Importe de dinero no válido",
      "notEmpty": "Este campo no puede estar vacío",
      "notIdenticalIban": "Confirme que el IBAN debe ser el mismo que el IBAN",
      "unrecognisedDateTime": "Fecha y hora no reconocidas"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Elija la experiencia a la que desea asignar la nueva disponibilidad.",
      "title": "Añadir cita a la experiencia"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Actualizar",
      "title": "Hay una nueva versión de bookingkit disponible"
    }
  },
  "pagination": {
    "next": "Siguiente",
    "previous": "Anterior"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Añadir cita",
    "disableHere": "Deshabilite los accesos directos aquí",
    "enableShortcuts": "Habilitar accesos directos",
    "keyboardShortcuts": "Métodos abreviados de teclado",
    "ok": "Aceptar",
    "powerShortcuts": "Métodos abreviados",
    "search": "Buscar",
    "searchShortcutToastInfoMac": "Pulse ⌘ + F de nuevo para activar la búsqueda del navegador nativo",
    "searchShortcutToastInfoWindows": "Pulse Ctrl + F de nuevo para activar la búsqueda del navegador nativo",
    "toastMessage": "Los accesos directos están habilitados por defecto.",
    "vendorShortcutToastInfoMac": "Pulse ⌘ + M de nuevo para activar el acceso directo del navegador nativo",
    "vendorShortcutToastInfoWindows": "Pulse Ctrl + M de nuevo para activar el acceso directo del navegador nativo",
    "vendorSwitch": "Cambio de proveedor"
  },
  "search": {
    "accepted": "ACEPTADO",
    "archived": "ARCHIVADO",
    "canceled": "CANCELADO",
    "coupon": "Cupón",
    "declined": "RECHAZADO",
    "expired": "CADUCADO",
    "failure": "ERROR",
    "feature": "Función",
    "flexibleTicket": "Billete flexible",
    "invalid": "No válido",
    "noResultFound": "No se ha encontrado ningún resultado",
    "noResultFoundMessage": "Su búsqueda no coincide con ningún resultado. Pruebe de otra forma.",
    "open": "ABIERTO",
    "order": "Pedido",
    "pending": "PENDIENTE",
    "reserved": "RESERVADO",
    "search": "Buscar",
    "searchEntryMessage": "Puede buscar:",
    "searchError": "Ha ocurrido un error...",
    "searchErrorMessage": "Estamos trabajando para solucionar el problema. Inténtelo de nuevo pronto.",
    "searchFeatureEntryMessage": "Funciones por nombres.",
    "searchOrderEntryMessage": "pedidos por identificador, cliente u otros datos relacionados con el pedido;",
    "searchScopes": {
      "all": "Todo",
      "coupons": "Cupones",
      "features": "Funciones",
      "flexibleTickets": "Billetes flexibles",
      "orders": "Pedidos",
      "vouchers": "Vales"
    },
    "searchVoucherEntryMesaage": "Vales por código, título o descripción;",
    "searchVoucherEntryMessage": "Vales por código, título o descripción;",
    "ticketTypes": {
      "all": "Todo",
      "coupons": "Cupones",
      "features": "Funciones",
      "flexibleTickets": "Billetes flexibles",
      "orders": "Pedidos",
      "vouchers": "Vales"
    },
    "valid": "Válido",
    "voucher": "Vale"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Datos de la empresa"
      },
      "contract": {
        "label": "Contrato"
      },
      "main": {
        "label": "cuenta"
      },
      "profile": {
        "label": "Perfil"
      },
      "signOut": {
        "label": "Cerrar sesión"
      },
      "userManagement": {
        "label": "Administración de usuarios"
      },
      "vendorSwitcher": {
        "label": "Conmutador de proveedores"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "Pagos de API"
      },
      "applicaitonLimits": {
        "label": "Límites de aplicación"
      },
      "application": {
        "label": "Aplicación"
      },
      "applicationLimits": {
        "label": "Límites de aplicación"
      },
      "changeRates": {
        "label": "Cambiar tasas"
      },
      "chargeVouchers": {
        "label": "Pagar con vales"
      },
      "claimAccount": {
        "label": "Reclamar cuenta"
      },
      "createAccount": {
        "label": "Crear cuenta"
      },
      "dashboard": {
        "label": "Panel"
      },
      "disableEventDates": {
        "label": "Deshabilitar fechas de eventos"
      },
      "emailConfiguration": {
        "label": "Configuración de correo electrónico"
      },
      "importCoupons": {
        "label": "Importar cupones"
      },
      "importOrders": {
        "label": "Importar pedidos"
      },
      "importVouchers": {
        "label": "Importar vales"
      },
      "invoices": {
        "label": "Facturas"
      },
      "KYCUnverified": {
        "label": "No verificado por KYC"
      },
      "main": {
        "label": "Administrador"
      },
      "manageAPI": {
        "label": "Administrar API"
      },
      "manageBkUsers": {
        "label": "Administrar usuarios de bk"
      },
      "mangopayDisputes": {
        "label": "Conflictos de MangoPay"
      },
      "marketingPartners": {
        "label": "Socios de marketing"
      },
      "outstandingPayments": {
        "label": "Pagos pendientes"
      },
      "paymentRequests": {
        "label": "Solicitudes de pago"
      },
      "payoutChannels": {
        "label": " Canales de pago"
      },
      "paypalPaymentRequests": {
        "label": "Solicitudes de pago de PayPal"
      },
      "provider": {
        "label": "Proveedor"
      },
      "rates": {
        "label": "Tasas"
      },
      "resellerPaymentStatus": {
        "label": "Estado de pago del revendedor"
      },
      "setupFee": {
        "label": "Tasa de instalación"
      },
      "translationTool": {
        "label": "Herramienta de traducción"
      },
      "unknownPaymentrequests": {
        "label": "Solicitudes de pago desconocidas"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Vista de día"
      },
      "feed": {
        "label": "Fuente de calendario"
      },
      "main": {
        "label": "Calendario"
      },
      "month": {
        "label": "Vista mensual"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Paquetes"
      },
      "experiences": {
        "label": "Experiencias"
      },
      "flexibleOffers": {
        "label": "Ofertas flexibles"
      },
      "main": {
        "label": "Inventario"
      },
      "products": {
        "label": "Productos"
      },
      "resources": {
        "label": "Recursos"
      }
    },
    "marketplace": {
      "main": {
        "label": "Aplicación Marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Billetes flexibles"
      },
      "main": {
        "label": "Pedidos"
      },
      "orders": {
        "label": "Pedidos"
      },
      "participants": {
        "label": "Participantes"
      },
      "promotionalCoupons": {
        "label": "Cupones promocionales"
      },
      "requests": {
        "label": "Solicitudes"
      },
      "vouchers": {
        "label": "Vales"
      }
    },
    "overview": {
      "main": {
        "label": "Resumen"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Agregar estadísticas"
      },
      "exports": {
        "label": "Exportaciones"
      },
      "main": {
        "label": "Informes"
      },
      "monthlyStatement": {
        "label": "Estado mensual"
      },
      "statements": {
        "label": "Estados"
      },
      "statistics": {
        "label": "Estadísticas"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Revendedores"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Mis socios"
      },
      "orders": {
        "label": "Pedidos"
      },
      "platforms": {
        "label": "Integración de distribuidores"
      },
      "promote": {
        "label": "Promover experiencias locales"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Configuración de pago"
      },
      "emailCampaigns": {
        "label": "Campañas de correo electrónico"
      },
      "localExperiences": {
        "label": "Experiencias locales"
      },
      "main": {
        "label": "Vender"
      },
      "paymentOptions": {
        "label": "Opciones de pago"
      },
      "paymentProviders": {
        "label": "Proveedores de pagos"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Fuente de calendario"
      },
      "emailConfiguration": {
        "label": "Configuración de correo electrónico"
      },
      "invoiceLayout": {
        "label": "Diseño de factura"
      },
      "invoiceNNumber": {
        "label": "Número de factura"
      },
      "invoiceNumber": {
        "label": "Número de factura"
      },
      "main": {
        "label": "Ajustes"
      },
      "modules": {
        "label": "Módulos"
      },
      "ticketConfiguration": {
        "label": "Configuración de billetes"
      },
      "translations": {
        "label": "Traducciones"
      },
      "vat": {
        "label": "IVA"
      },
      "voucherConfiguration": {
        "label": "Configuración de vales"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "Aplicación Marketplace"
      },
      "appointment": {
        "label": "Añadir cita"
      },
      "booking": {
        "label": "Reserva"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Póngase en contacto con la asistencia"
      },
      "copyToClipboard": {
        "failure": "No se pudo copiar el enlace para el proveedor",
        "success": "Enlace específico copiado para el proveedor:"
      },
      "date": {
        "label": "Fecha"
      },
      "experience": {
        "label": "Experiencia"
      },
      "knowledgeBase": {
        "label": "Base de conocimientos",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Asistente de incorporación (TBC)"
      },
      "powerShortcuts": {
        "label": "Métodos abreviados"
      }
    },
    "updates": {
      "main": {
        "label": "Actualizaciones de productos"
      },
      "mainBadge": {
        "label": "Nuevo"
      }
    },
    "wallet": {
      "main": {
        "label": "Monedero"
      },
      "partnerPayments": {
        "label": "Pagos de socios"
      },
      "verification": {
        "label": "Verificación"
      },
      "walletManagement": {
        "label": "Gestión del monedero"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Proveedores disponibles",
    "dismiss": "Descartar",
    "navigate": "Navegar",
    "noResult": "No se ha encontrado ningún resultado",
    "recentUsed": "Utilizado recientemente",
    "resultsLabel": "Proveedores encontrados",
    "searchError": "Ha ocurrido un error...",
    "searchErrorMessage": "Estamos trabajando para solucionar el problema. Inténtelo de nuevo pronto.",
    "searchForVendor": "Buscar proveedor",
    "select": "Seleccionar",
    "storeResultsLabel": "Proveedores disponibles",
    "supplierAlreadyInUseError": "Actualmente está utilizando esta cuenta de proveedor",
    "switchVendor": "Cambiar proveedor",
    "wishmorning": "Buenos días"
  }
}