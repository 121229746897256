export const AUTHENTICATION_SERVICE = Symbol('AUTHENTICATION_SERVICE');
export const AUTHORIZATION_SERVICE = Symbol('AUTHORIZATION_SERVICE');
export const ACCOUNTING_SERVICE = Symbol('ACCOUNTING_SERVICE');
export const INVENTORY_SERVICE = Symbol('INVENTORY_SERVICE');
export const LOGGER_SERVICE = Symbol.for('LOGGER_SERVICE');
export const SUPPLIER_SERVICE = Symbol('SUPPLIER_SERVICE');
export const SUPPLIER_STATE_SERVICE = Symbol('SUPPLIER_STATE_SERVICE');
export const USER_STATE_SERVICE = Symbol('USER_STATE_SERVICE');
export const USER_SERVICE = Symbol('USER_SERVICE');
export const ROUTING_SERVICE = Symbol('ROUTING_SERVICE');
export const ORDER_SERVICE = Symbol('ORDER_SERVICE');
export const VOUCHER_SERVICE = Symbol('VOUCHER_SERVICE');
export const COUPON_SERVICE = Symbol('COUPON_SERVICE');
export const MODULE_SERVICE = Symbol('MODULE_SERVICE');
export const BK_APPS_SERVICE = Symbol('BK_APPS_SERVICE');
// Factories
export const BOOKINGKIT_API_FACTORY = Symbol('BOOKINGKIT_API_FACTORY');
